<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Payment Status"
            label-for="PaymentStatus"
          >
            <v-select
              v-model="filters['payment_status']"
              :options="paymentStatusOption"
              :reduce="option => option.key"
              placeholder="Select Status"
              label="name"
            >
              <template
                v-slot:option="option"
                style="height: 200px"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Business Name"
            label-for="business option"
          >
            <v-select
              v-model="filters['tenant.name']"
              :options="clientOptions"
              :reduce="option => option.name"
              placeholder="Select Business"
              label="name"
            >
              <template
                v-slot:option="option"
                style="height: 200px"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <div class="m-2">
        <b-row class="d-flex justify-content-end align-items-center mb-0">
          <div>
            <JsonExcel
              class="btn p-0"
              :data="rows"
              :fields="json_fields"
              type="csv"
              :name="`Subscriptions- Billing - Page ${page}.xls`"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="Download"
                  size="15"
                />
              </b-button>
            </JsonExcel>
            <JsonExcel
              class="btn p-0"
              :fetch="getBillingDetailsNoPagination"
              :before-generate="__showJsonDownloadAlert"
              :fields="json_fields"
              type="csv"
              name="Subscriptions-Billing.xls"
            >
              <b-button
                v-b-tooltip.hover.v-primary="'Download Excel - All'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                size="sm"
              >
                <mdicon
                  name="DownloadMultiple"
                  size="15"
                />
              </b-button>
            </JsonExcel>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary="'Refresh Table'"
              variant="outline-primary"
              size="sm"
              @click="onClickRefresh()"
            >
              <feather-icon
                icon="RefreshCwIcon"
              />
            </b-button>
          </div>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #cell(client_name)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    v-if="data.item.logo === null"
                    :text="avatarText(data.value)"
                    size="32"
                    variant="light-primary"
                  />
                  <b-avatar
                    v-else
                    :src="`${data.item.logo}`"
                    size="32"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                    >
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(invoice_period)="data">
                <div class="width-250 d-flex flex-column align-items-start">
                  <div>
                    <b>Start Date&nbsp;:</b>&nbsp;&nbsp;{{ data.item.start_date || '---' }}
                  </div>
                  &nbsp;
                  <div>
                    <b>End Date&nbsp;:</b>&nbsp;&nbsp;{{ (data.item.end_date ) || '---' }}
                  </div>
                </div>
              </template>
              <template #cell(tenant.name)="data">
                <div
                  class="text-dark"
                >
                  {{ data.item.tenant.business_name === null ? data.item.tenant.name : data.item.tenant.business_name }}
                </div>
              </template>
              <template #cell(total_amount)="data">
                {{ data.value || '---' }}
              </template>
              <template #cell(paid_via)="data">
                {{ data.value === 'bank_transfer' ? 'Bank Transfer' : (data.value === 'pay_here' ? 'Pay here' : (data.value === 'credit_basis' ? 'Credit basis' : '---')) }}
              </template>
              <template #cell(start_date)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(payment_due_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(paid_date)="data">
                {{ __dateTimeFormatter(data.value) || '---' }}
              </template>
              <template #cell(payment_status)="data">
                <b-badge
                  :variant="`light-${data.value === 'due' ? 'danger' : (data.value === 'paid' ? 'warning' : 'success')}`"
                >
                  {{ data.value === 'due' ? 'DUE' : (data.value === 'paid' ? 'PAID' : 'APPROVED') }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'view-invoice', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="EyeIcon" />
                    View Invoice
                  </b-dropdown-item>
                  <!-- <b-dropdown-item
                    v-if="!data.item.is_paid"
                    :to="{ name: 'subscribe', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="CreditCardIcon" />
                    Check Payment
                  </b-dropdown-item> -->
                  <b-dropdown-item
                    @click="openInvoiceModal(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    Check Payment
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <ViewPaymenInvoicetModal ref="viewPaymenInvoicetModal" />
    <package-list-modal ref="packageListModal" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BFormGroup,
  // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import JsonExcel from 'vue-json-excel'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ViewPaymenInvoicetModal from '@/components/Subscription/ViewPaymentInvoiceModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')
const clientRepository = RepositoryFactory.get('client')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownItem,
    vSelect,
    JsonExcel,
    ViewPaymenInvoicetModal,
    BFormGroup,
    BCardActions,
    // BFormInput,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      filters: {},
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'created_at',
          label: 'Created Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'invoice_period',
          label: 'Invoice Period',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'inv_ref_no',
          label: 'Ref Number',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'tenant.name',
          label: 'Business Name',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'billed_order_count',
          label: 'Orders handled',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'total_amount',
          label: 'Total Amount',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'paid_via',
          label: 'Paid Via',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'paid_date',
          label: 'Paid Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'payment_status',
          label: 'Payment status',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'payment_due_date',
          label: 'Due Date',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-left',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      clientOptions: [],
      json_fields: {
        'Created Date': {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'Started Date': 'start_date',
        'End Date': 'end_date',
        'Business Name': 'tenant.name',
        'Orders handled': 'billed_order_count',
        'Reference Number': 'inv_ref_no',
        'Paid Via': 'paid_via',
        'Paid Date': 'paid_date',
        'Total Amount': 'total_amount',
        'Payment status': 'payment_status',
        'Due date': 'payment_due_date',
      },
      activeSubscription: null,
      paymentStatusOption: [
        {
          key: 'due',
          name: 'DUE',
        },
        {
          key: 'paid',
          name: 'PAID',
        },
        {
          key: 'approved',
          name: 'APPROVED',
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getBillingDetails()
      },
    },
    page() {
      this.getBillingDetails()
    },
    perPage() {
      this.getBillingDetails()
    },
  },
  mounted() {
    this.getClientList()
  },
  methods: {
    onClickViewPayment(id) {
      this.$refs.viewPaymentModal.openModal(id)
    },
    onClickMakePayment() {
      this.$router.push({
        name: 'subscribe',
      })
    },
    openInvoiceModal(id) {
      this.$refs.viewPaymenInvoicetModal.openModal(id)
    },
    async getBillingDetails() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const { data } = (await SubscriptionRepository.getBillingData(this.page, this.filterQuery, this.perPage, this.sort))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getBillingDetailsNoPagination() {
      try {
        const { data } = (await SubscriptionRepository.getBillingDetailsNoPagination())
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async getClientList() {
      this.loading = true
      try {
        const { data } = await clientRepository.getClientListForDropdown()
        this.clientOptions = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    handleChangePage(page) {
      this.page = page
      this.getBillingDetails()
    },
    filterQueryUpdate() {
      this.getBillingDetails()
    },
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchSubscriptionList()
      }
    },
    onClickRefresh() {
      this.getBillingDetails()
    },
  },
}
</script>
