<template>
  <b-modal
    id="package-file-upload"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="View Payment Slip "
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div
        style="height: 550px;"
      >
        <b-img
          v-if="isImageFile(Image)"
          :src="Image"
          style="height: 100%; width: 100%;"
          alt="payment slip image"
        />

        <!-- Display PDF viewer if the file is a PDF -->
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <embed
          v-else-if="isPDFFile(Image)"
          :src="Image"
          type="application/pdf"
          style="height: 100%; width: 100%;"
        />

        <!-- Display a message if no image or PDF file is available -->
        <div v-else>
          No PDF or IMAGE file available. Please notify to upload again.
        </div>
      </div>
    </b-overlay>
    <div class="d-flex justify-content-end">
      <b-button
        v-show="Items.payment_status !== 'approved'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-success"
        class="mb-1"
        @click="approvePaymentSlip()"
      >
        Approve
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  // BTable,
  BOverlay,
  VBModal,
  BImg,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { bucketUrl } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')

function initialState() {
  return {
    open: false,
    invoiceId: null,
    Image: null,
    data: {},
    loading: false,
    rows: [],
    Items: '',
    fields: [
      {
        key: 'name',
        label: 'Package Name',
        sortable: false,
      },
      {
        key: 'description',
        label: 'Description',
        sortable: false,
      },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    // BTable,
    BOverlay,
    BImg,
    BButton,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  mounted() {
    this.invoiceId = Number(this.$route.params.id)
  },
  methods: {
    async openModal(invoiceId) {
      await Object.assign(this.$data, initialState())
      this.invoiceId = invoiceId
      this.open = true
      this.getPaymentInvoiceImage()
    },
    isImageFile(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png']
      const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase()
      return imageExtensions.includes(fileExtension)
    },
    isPDFFile(url) {
      const pdfExtensions = ['.pdf']
      const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase()
      return pdfExtensions.includes(fileExtension)
    },
    async getPaymentInvoiceImage() {
      try {
        this.loading = true
        const { data } = await SubscriptionRepository.getPaymentInvoiceById(this.invoiceId)
        this.Image = `${bucketUrl}${data.data.deposit_slip_url}`
        this.items = data.data
        this.loading = false
      } catch (e) {
        this.loading = false
        this.convertAndNotifyError(e)
      }
    },
    async approvePaymentSlip() {
      this.loading = true
      try {
        const res = await SubscriptionRepository.approveSlip(this.invoiceId)
        if (res.status === 200) {
          this.showSuccessMessage(res.data.message)
          this.$router.go()
        } else {
          this.showErrorMessage(res.data.errors)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>
</style>
